import React, { useRef, useState } from "react";
import "./About.css";
import image from "../../../images/06.jpg";
import { easeOut, motion } from "framer-motion";

function About() {
  const [selected, setSelected] = useState("return");
  const riskRef = useRef("");
  const returnRef = useRef("");

  return (
    <div className="home_about">
      <div className="home_about_container">
        <section className="left">
          <motion.h1
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Where Capital Meets Opportunity
          </motion.h1>
          <motion.p
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            We have expert advisor and analyst who will hand hold and take you
            from start to finish with your investment.
          </motion.p>

          <div className="buttom">
            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
              className={`option ${selected === "return" ? "active" : ""}`}
              onClick={() => {
                setSelected("return");
              }}
            >
              <span className="line"></span>
              <div className="contentWrapper">
                <span className="circle"></span>
                <div className="content">
                  <h1>Maximize Return</h1>
                  <p
                    ref={returnRef}
                    style={
                      selected === "return"
                        ? { height: returnRef.current.scrollHeight }
                        : { height: "0px" }
                    }
                  >
                    Our team will asses any investment from different angles
                    using variables to optimize the final result to a greater
                    extent as possible.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
              className={`option ${selected === "risk" ? "active" : ""}`}
              onClick={() => {
                setSelected("risk");
              }}
            >
              <span className="line"></span>
              <div className="contentWrapper">
                <span className="circle"></span>
                <div className="content">
                  <h1>Minimize Risk</h1>
                  <p
                    ref={riskRef}
                    style={
                      selected === "risk"
                        ? { height: riskRef.current.scrollHeight }
                        : { height: "0px" }
                    }
                  >
                    Before commencing with any project, we have a risk assesment
                    team going through all possible variables to make to
                    mitigate the risk as much as possible
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
        <section className="right">
          <motion.img
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
            src={image}
            alt=""
          />
        </section>
      </div>
    </div>
  );
}

export default About;
