import React from "react";
import "./Service.css";
import { easeOut, motion } from "framer-motion";

import image1 from "../../../images/mansion5.jpg";
import image2 from "../../../images/s2.jpg";
import image3 from "../../../images/mansion6.jpg";
import image4 from "../../../images/mansion7.jpg";
import image5 from "../../../images/mansion8.jpg";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Mailing List",
    desc: "Opportunities will be sent 24 hours after released to the Inner circle",
    image: image1,
  },
  {
    title: "Services We Provide",
    desc: "",
    image: image2,
  },
  {
    title: "Inner Circle",
    desc: "Valuing our client, Inner Circle is design so we can work taylore search your criteria and work in bespoke manner.",
    image: image3,
  },
  {
    title: "Invest With Ease",
    desc: "Providing a garaunteed above the market return whilst investing with us long term.",
    image: image4,
  },
  {
    title: "Join Venture",
    desc: "We can work together creating synergy and moving towards the same successful outcome.",
    image: image5,
  },
];

function Service() {
  return (
    <div className="service">
      <div className="service_container">
        {data?.map((item, i) => {
          return (
            <motion.div
              whileInView={
                i === 0 || i === 3
                  ? { x: [-100, 0], opacity: [0, 1] }
                  : i === 1
                  ? { y: [-100, 0], opacity: [0, 1] }
                  : { x: [100, 0], opacity: [0, 1] }
              }
              transition={{ duration: 0.8, ease: easeOut }}
              viewport={{ once: true }}
              key={i}
              className="item"
              style={{ backgroundImage: `url(${item?.image})` }}
            >
              <div className="back"></div>
              <section>
                <h1>{item?.title}</h1>
                <p>{item?.desc}</p>
              </section>

              <Link to={"/contact-us"}>
                {i === 1 ? "Contact Us" : "Know More"}
              </Link>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}

export default Service;
