import "./Highlight.css";
import { easeOut, motion } from "framer-motion";
import background1 from "../../../images/ss1.jpg";
import background2 from "../../../images/ss2.jpg";

function Highlight() {
  return (
    <div className="highlight">
      <div className="highlight_container">
        <div className="top">
          <motion.h1
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.6, ease: easeOut }}
            viewport={{ once: true }}
          >
            Sourcing
            <span></span>
          </motion.h1>
          <motion.p
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className="highlight_details"
          >
            Property sourcing, a cruticial part of the engine of investing in
            properties. This part defines the success or faliure for the
            investor. We take care of our clients from 1 to z, handholding them
            throughout their investment journey.
            <br />
            <br />
            Investing in property doesn't need to be difficult, time-consuming,
            or stressful. A lot of investors, individuals, companies, or large
            institutions, find that getting their numbers to work can be
            problematic and stops them for moving forward with the deal.
            <br />
            <br />
            Finding the right location, level of returns, type of property,
            expected growth, amount of rental exits, and condition (to name a
            few), can (and often does) take time. 
            <br />
            <br />
            We're here to help to alleviate this inefficiency. Investors get
            their time back, whilst also getting exactly what they require.
            <br /> <br />
            Our services are fully bespoke. Meaning that we'll provide a
            complimentary consultation with you to determine your exact
            criteria. Once this has been captured, we'll hunt specifically on
            your behalf for your exact investment. Outsourced property finding,
            but carried out by industry professionals. If this is something of
            interest, click on the link below to organise your free
            consultation.
          </motion.p>
        </div>

        <div className="item reverse">
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${background1})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.8, ease: easeOut }}
              viewport={{ once: true }}
            >
              Basic Plan - Mailing List
            </motion.h1>
            <span></span>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.9, ease: easeOut }}
              viewport={{ once: true }}
            >
              Our Basic Plan is there to provide you with constant supply of
              deals coming directly to your email. The deals will vary from
              different strategy and less personalises service , designed for
              client who want to access the numerious deals.
              <br /> <br />
              Once registered, the deal will flow directly to your email. It
              will be on first come first serve basis, reserving the deal once
              you are ready to move forward.
              <br /> <br />
              Sign up to mailing list now and start getting the deals coming to
              you.
            </motion.p>
            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
            >
              <a
                href="https://form.jotform.com/233242619539056"
                rel="no-follow"
              >
                Learn More
              </a>
            </motion.div>
          </div>
        </div>
        <div className="item">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${background2})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.8, ease: easeOut }}
              viewport={{ once: true }}
            >
              Inner Circle
            </motion.h1>
            <span></span>
            <motion.p
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.9, ease: easeOut }}
              viewport={{ once: true }}
            >
              Our Inner Circle is design to work with our more serious client
              who are more active and determied on achieving their property
              goals. Rather than going through facebook deals or email marketing
              deals, missing out or compete with other, Inner circle is created
              just so you can avoid this and work with us in confidence.
              <br /> <br />
              When you join our inner circle, you go through our handhold
              service, work exlcusively with you providing a bespoke service on
              finding your perfect deals as per your criteria. <br /> <br />
              Commitment fee: We take 2k as commitment fee for our Inner circle
              clients who are more active and dertermied on achieving their
              goals. This will be deducted from your first deal. <br /> <br />
              If you would like to join our Inner circle, please fill the form
              with what your criterias are and one of our team will give you a
              15 mins calls session to go over your criteria in details.
            </motion.p>
            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
            >
              <a
                href="https://form.jotform.com/233242619539056"
                rel="no-follow"
              >
                Learn More
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Highlight;
